<template>
 <div class="container content" v-if="activeUser.type == 'admin'">
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                
                <article class="tile is-child box">
                    <p class="title">Raportointi</p>
         
                    <div style="float:right; margin-top: 10px;  padding: 10px;">
                        <b-field label="Aikaväli">
                                <b-datepicker
                                    ref="datepicker"
                                    :month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
                                    :day-names="['su','ma','ti','ke','to','pe','la']"
                                    :date-formatter="_formatDate"
                                    :first-day-of-week="1"
                                    v-model="from_date">
                                </b-datepicker>
                                <b-datepicker
                                    ref="datepicker"
                                    :month-names="['tammi','helmi','maalis','huhti','touko','kesä','heinä','elo','syys','loka','marras','joulu']"
                                    :day-names="['su','ma','ti','ke','to','pe','la']"
                                    :date-formatter="_formatDate"
                                    :first-day-of-week="1"
                                    v-model="to_date">
                                </b-datepicker>
                                <button @click="getReport" class="button">Hae raportti</button>
                        </b-field>
                    </div>
                    <div style="float:right; margin-top: 10px; padding: 10px;">
                    <b-field label="Valitse mudostettava raportti">
                        <b-radio-button v-model="selectedReport"
                            native-value="expire"
                            type="is-light is-primary is-outlined">
                            <span>Vanhentuva saldo</span>
                        </b-radio-button>
                        <b-radio-button v-model="selectedReport"
                            native-value="purchases"
                            type="is-light is-primary is-outlined">
                            <span>Koulutus ostot</span>
                        </b-radio-button>
                        <b-radio-button v-model="selectedReport"
                            native-value="orders"
                            type="is-light is-primary is-outlined">
                            Saldo ostot
                        </b-radio-button>
                    </b-field>
                    </div>
                    <hr />
                    <b-field label="Suodata raporttia hakusanalla">
                        <b-input placeholder="Etsi..."
                        v-model="filterString"
                            type="search"
                            icon="magnify">
                        </b-input>
                    </b-field>

                    <table v-if="selectedReport == 'expire'">
                        <tr>
                            <th>Vanhentunut saldo</th>
                            <th>Vanhenemispäivä</th>
                            <th>Käyttäjä</th>
                        </tr>
                        <tr v-for="account in filteredAccounts" :key="account.uuid">
                            <td>{{ account.balance }}</td>
                            <td>{{ account.expiration_date | finpvm }}</td>
                            <td v-if="account.user">{{ account.user.first_name }} {{ account.user.last_name }}</td>
                            <td v-if="!account.user && account.employee"><span style="color:red;">{{ account.employee.first_name }} {{ account.employee.last_name }} (Ei ole luonnut tiliä / lunastanut seteleitä.)</span></td>
                            <td v-if="(!account.employee && !account.user)"></td>
                        </tr>
                        <tr>
                            <th>Yhteensä: {{ totalExpiredBalance }}&euro;</th>
                        </tr>
                    </table>
                    <table v-if="selectedReport == 'purchases'">
                        <tr>
                            <th>Hinta</th>
                            <th>Koulutusyritys</th>
                            <th>Kurssi</th>
                            <th>Ostopäivä</th>
                            <th>Käyttäjä</th>
                        </tr>
                        <tr v-for="purchase in filteredPurchases" :key="purchase.uuid">
                            <td>{{ purchase.price }}</td>
                            <td>{{ purchase.education_partner.name }}</td>
                            <td>{{ purchase.description }}</td>
                            <td>{{ purchase.created_at | finpvm }}</td>
                            <td>{{ purchase.user.first_name }} {{ purchase.user.last_name }}</td>
                        </tr>
                        <tr>
                            <th>Yhteensä: {{ totalPurchasesValue }}&euro;</th>
                        </tr>
                    </table>
                    <table v-if="selectedReport == 'orders'">
                        <tr>
                            <th>Arvo</th>
                            <th>Yritys</th>
                            <th>Käyttäjä</th>
                            <th>Ostopäivä</th>
                            <th>Laskutuspäivä</th>
                            <th>Maksupäivä</th>
                            <th>Tilanne</th>
                        </tr>
                        <tr v-for="order in filteredOrders" :key="order.uuid">
                            <td>{{ order.amount }}</td>
                            <td>{{ order.company.name }}</td>
                            <td>{{ order.user.first_name }} {{ order.user.last_name }}</td>
                            <td>{{ order.created_at | finpvm }}</td>
                            <td v-if="order.billed_datetime">{{ order.billed_datetime | finpvm }}</td>
                            <td v-if="!order.billed_datetime"></td>
                            <td v-if="order.payment_confirmed_datetime">{{ order.payment_confirmed_datetime | finpvm }}</td>
                            <td v-if="!order.payment_confirmed_datetime"></td>
                            <td>{{ order.status | orderStatusToHuman }}</td>
                        </tr>
                        <tr>
                            <th>Yhteensä: {{ totalOrdersValue }}&euro;</th>
                        </tr>
                    </table>
                </article>
            </div>
        </div>  
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
//import excel from 'vue-excel-export';
export default {
    components: {
        //excel
    },
    filters: {
        finpvm: function (_date) {
            return moment(_date, "YYYY-MM-DD hh:mm:ss").format(
                "DD.MM.YYYY"
            );
        },
        orderStatusToHuman: function (status) {
            if(status == 'new-order') {
                return "Uusi"
            }
            if(status == 'billed') {
                return "Laskutettu"
            }
            if(status == 'paid') {
                return "Maksettu"
            }
            if(status == 'cancelled') {
                return "Peruttu"
            }
            return status;
        },
    },
    data() {
        return {
            filterString: '',
            selectedReport: false,
            purchases: [],
            accounts: [],
            orders: [],
            from_date: this.$moment()._d,
            to_date: this.$moment()._d,
            type: '',
        }
    },
    computed: {
        filteredAccounts() {
            if(this.filterString == '') {
                return this.accounts;
            }
            let original = JSON.parse(JSON.stringify(this.accounts));
            let filtered = [];
            original.forEach((row) => {
                let name = "";
                if(row.employee) {
                    name = row.employee.first_name.toLowerCase() + ' ' + row.employee.last_name.toLowerCase();
                }
                if(row.user) {
                    name = row.user.first_name.toLowerCase() + ' ' + row.user.last_name.toLowerCase();
                }
                if(name.includes(this.filterString.toLocaleLowerCase())) {
                    filtered.push(row);
                }
            })
            return filtered;
        },
        filteredOrders() {
            if(this.filterString == '') {
                return this.orders;
            }
            let original = JSON.parse(JSON.stringify(this.orders));
            let filtered = [];
            original.forEach((row) => {
                let pushed = false;
                if(row.company.name.toLowerCase().includes(this.filterString.toLocaleLowerCase())) {
                    if(!pushed) {
                        filtered.push(row);
                        pushed = true;
                    }
                }
                let name = row.user.first_name.toLowerCase() + ' ' + row.user.last_name.toLowerCase();
                if(name.includes(this.filterString.toLocaleLowerCase())) {
                    if(!pushed) {
                        filtered.push(row);
                        pushed = true;
                    }
                }
                if(this.$options.filters.orderStatusToHuman(row.status).toLowerCase().includes(this.filterString.toLocaleLowerCase())) {
                    if(!pushed) {
                        filtered.push(row);
                        pushed = true;
                    }
                }
            })
            return filtered;
        },
        filteredPurchases() {
            if(this.filterString == '') {
                return this.purchases;
            }
            let original = JSON.parse(JSON.stringify(this.purchases));
            let filtered = [];
            original.forEach((row) => {
                let pushed = false;
                if(row.education_partner.name.toLowerCase().includes(this.filterString.toLocaleLowerCase())) {
                    if(!pushed) {
                        filtered.push(row);
                        pushed = true;
                    }
                }
                if(row.description.toLowerCase().includes(this.filterString.toLocaleLowerCase())) {
                    if(!pushed) {
                        filtered.push(row);
                        pushed = true;
                    }
                }
                let name = row.user.first_name.toLowerCase() + ' ' + row.user.last_name.toLowerCase();
                if(name.includes(this.filterString.toLocaleLowerCase())) {
                    if(!pushed) {
                        filtered.push(row);
                        pushed = true;
                    }
                }
            })
            return filtered;
        },
        totalExpiredBalance() {
            let total = 0;
            this.filteredAccounts.forEach((account) => {
                total += account.balance;
            })
            return total;
        },
        totalPurchasesValue() {
            let total = 0;
            this.filteredPurchases.forEach((purchase) => {
                total += purchase.price;
            })
            return total;
        },
        totalOrdersValue() {
            let total = 0;
            this.filteredOrders.forEach((order) => {
                total += order.amount;
            })
            return total;
        }
    },
    methods: {
        getReport() {
            if(this.selectedReport == 'expire') {
                this.getExpiringBalanceReport();
            }
            if(this.selectedReport == 'purchases') {
                this.getPurchasesReport();
            }
            if(this.selectedReport == 'orders') {
                this.getOrdersReport();
            }
        },
        convertToSqlStamp(jsDate) {
            return moment(jsDate, "YYYY-MM-DD hh:mm:ss").format(
                "YYYY-MM-DD"
            );
        },
        getExpiringBalanceReport() {
            axios.post('/report/expiring-balance', {
                date_from: this.convertToSqlStamp(this.from_date),
                date_to: this.convertToSqlStamp(this.to_date),
            }).then((response) => {
                this.accounts = response.data; 
            })
        },
        getPurchasesReport() {
            axios.post('/report/purchases', {
                date_from: this.convertToSqlStamp(this.from_date),
                date_to: this.convertToSqlStamp(this.to_date),
            }).then((response) => {
                this.purchases = response.data; 
            })
        },
        getOrdersReport() {
            axios.post('/report/orders', {
                date_from: this.convertToSqlStamp(this.from_date),
                date_to: this.convertToSqlStamp(this.to_date),
            }).then((response) => {
                this.orders = response.data; 
            })
        },

    }
}
</script>